<template>
  <div class="wrapper">
    <div class="nav-wrapper">
      <div class="logo-wrapper">
        <img src="../assets/nav_logo.png" alt="logo">
      </div>
      <div class="menu-wrapper">
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/about">关于我们</router-link>
          </li>
          <li>
            <router-link to="/driver-recruitment">司机招募</router-link>
          </li>
          <li>
            <router-link to="/partner-recruitment">合作伙伴招募</router-link>
          </li>
          <li>
            <router-link to="/join-us">加入我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Nav',
  }
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  // text-align: center;
  .nav-wrapper {
    width: 46.88vw;
    margin: 0 auto;
    padding: 15px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > .logo-wrapper {
      height: 80px;
      margin-right: 6.77vw;
    }
    > .menu-wrapper {
      > ul {
        display: flex;
        flex-wrap: no-wrap;
        > li {
          margin-left: 80px;
          white-space: nowrap;
          font-size: 20px;
          color: #010101;
          font-weight: 200;
        }
      } 
    }
  }
}
</style>