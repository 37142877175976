<template>
  <div class="title-wrapper">
    <h2>
      <slot></slot>
    </h2>
    <div class="underline"></div>
  </div>
</template>

<script>
  export default {
    name: 'UnderlineTitle'
  }
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: normal;
  > h2{
    padding: 0;
    line-height: 1;
  }
  > .underline {
    margin-top: 30px;
    width: 90px;
    height: 8px;
    background-color: #f1981a;
  }
}
</style>
