<template>
  <div class="home">
    <!-- <Nav></Nav> -->
    <div class="swiper-container banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide1"><img src="../assets/banner_1.jpg" alt=""></div>
        <div class="swiper-slide swiper-slide2"><img src="../assets/banner_2.jpg" alt=""></div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div>   -->
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div> -->
      <!-- <div class="swiper-button-next"></div> -->
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <div class="introduction">
      <UnderlineTitle>公司介绍</UnderlineTitle>
      <div class="content">
        <img src="../assets/intro.png" alt="">
        <div class="text">
          <p>迪尔出行是青岛路远信息技术有限公司打造的智慧出行生态平台，依托移动互联网技术构建的面向全国商旅出行人士的网络预约出租汽车经营服务方。</p>
          <p>迪尔将以全新的姿态，全方位的布局，独特的运营思路，稳固的技术支撑，逐步实现品牌的行业基准，并引领行业更向前一步发展。</p>
        </div>
      </div>
    </div>

    <div class="platforms">
      <UnderlineTitle>合作出行平台</UnderlineTitle>
      <div class="content">
        <p>与高德、飞猪、各大企业用车达成战略合作，API深度对接，其他合作方紧密洽谈中…</p>
        <div class="image-wrapper">
          <img src="../assets/gaode_logo.png" alt="高德地图logo">
          <img src="../assets/feizhu_logo.png" alt="飞猪logo">
        </div>
      </div>
    </div>

    <div class="advantages">
      <UnderlineTitle>产品优势</UnderlineTitle>
      <div class="content">
        <div class="slogan">
          <span>迪 尔 出 行</span>
          <img src="../assets/advantanges_logo.png" alt="logo">
          <span>相 伴 每 一 程</span>
        </div>
        <div class="sub_title-wrapper">
          <span class="sub_title">主要服务</span>
        </div>
        <ul class="advantages_list">
          <li v-for="item in advantagesList" :key="item.id">
            <img :src="item.imgSrc" alt="">
            <span>{{ item.title }}</span>
            <span>{{ item.description_1 }}</span>
            <span>{{ item.description_2 }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="features">
      <div class="content">
        <div class="sub_title-wrapper">
          <span class="sub_title">产品特色</span>
        </div>
        <ul class="features_list">
          <li v-for="item in featuresList" :key="item.id">
            <div class="image-wrapper">
              <img :src="item.imgSrc" alt="">
            </div>
            <div class="text-wrapper">
              <p>{{ item.title }}</p>
              <p>{{ item.description }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="awards">
      <UnderlineTitle>公司荣誉</UnderlineTitle>
      <div class="content">
        <div class="honor-wrapper"></div>
        <div class="img-wrapper">
          <img src="../assets/license.png" alt="license">
        </div>
      </div>
    </div>

    


  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css' 

export default {
  name: 'Home',
  data(){
    return {
      swiperInstance: null,
      advantagesList: [
        {
          id: 1,
          imgSrc: require('../assets/advantage_1.png'),
          title: '即时用车',
          description_1: '随叫随到',
          description_2: '智能匹配，全职司机',
        },
        {
          id: 2,
          imgSrc: require('../assets/advantage_2.png'),
          title: '预约用车',
          description_1: '提前规划行程',
          description_2: '一切尽在掌握',
        },
        {
          id: 3,
          imgSrc: require('../assets/advantage_3.png'),
          title: '接送机/站',
          description_1: '无缝对接 行程无忧',
          description_2: '',
        },
      ],
      featuresList: [
        {
          id: 1,
          imgSrc: require('../assets/features_1.png'),
          title: '高性价比',
          description: '费用透明，消费放心，平台抽佣低，司机收入稳定，导致服务态度优良',
        },
        {
          id: 2,
          imgSrc: require('../assets/features_2.png'),
          title: '安全靠谱',
          description: '迪尔出行以互联网技术为依托的服务平台，整合供需关系，使用符合要求的司机和车辆，提供非巡游的汽车服务',
        },
        {
          id: 3,
          imgSrc: require('../assets/features_3.png'),
          title: '专注安全出行',
          description: '司机、车辆、双证严格审核，并进行严格培训和考试',
        },
        {
          id: 4,
          imgSrc: require('../assets/features_4.png'),
          title: '高效便捷',
          description: '创新、全场景网约车出行平台迪尔出行APP提供便捷、安全的智能出行服务。“一键约车”功能，实现了更快速叫车，同时优化叫车流程，提供了更便捷、高效的出行新体验',
        },
        {
          id: 5,
          imgSrc: require('../assets/features_5.png'),
          title: '全程订单追踪',
          description: '24小时订单追踪，未派订单及时跟进处理，降低爽约风险',
        },
        {
          id: 6,
          imgSrc: require('../assets/features_6.png'),
          title: '高服务质量',
          description: '迪尔深化培训司机服务流程，保证司机服务质量，主打安全出行，将安全做到极致',
        },
      ],
    }
  },
  mounted(){
    this.initSwiper()
  },
  methods: {
    initSwiper(){
      this.swiperInstance = new Swiper('.banner', {
          loop: true,
          effect: "fade",
          // navigation: {
          //   nextEl: ".banner .swiper-button-next",
          //   prevEl: ".banner .swiper-button-prev"
          // },
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          simulateTouch: false
      })
    },
    
  },
}
</script>
<style lang="scss" scoped>
.home {
  background-color: #fff;
  .swiper-container.banner {
    .swiper-wrapper {
      // height: 300px;
      width: 100%;
      > .swiper-slide {
        height: 100%;
        width: 100%;
        > img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .introduction {
    padding-top: 90px;
    > .content {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-left: 11.3vw;
      // padding-right: 20vw;
      margin: 0 auto;
      margin-top: 125px;
      min-width: 1200px;
      width: 78vw;
      padding-bottom: 80px;
      > img {
        width: 33.85vw;
        min-width: 510px;
        height: 18.8vw;
        min-height: 270px;
        padding-right: 5vw;
      }
      > div.text {
        width: 590px;
        font-size: 24px;
        font-weight: 200;
        > p {
          text-align: left;
          text-indent: 2em;
          line-height: 2;
        }
        > p:first-child{
          margin-bottom: 3vw;
        }
      }
    }
  }
  // .title-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 40px;
  //   font-weight: normal;
  //   > h2{
  //     padding: 0;
  //     line-height: 1;
  //   }
  //   > .underline {
  //     margin-top: 30px;
  //     width: 90px;
  //     height: 8px;
  //     background-color: #f1981a;
  //   }
  // }
  .platforms {
    background-color: #f5f5f5;
    padding-top: 100px;
    padding-bottom: 50px;
    > .content {
      padding-top: 50px;
      text-align: center;
      > p {
        margin-top: 20px;
        font-weight: 200;
      }
      > .image-wrapper {
        margin-top: 30px;
        > img:last-child {
          margin-left: 30px;
        }
      }
    }
  }
  .advantages {
    padding-top: 100px;
    padding-bottom: 80px;
    .content {
      text-align: center;
      margin-top: 36px;
      > .slogan {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: 200;
        > img {
          width: 20px;
          height: 20px;
          margin: 0 20px;
        }
        > span:first-child {
          text-align: right;
          width: 6.5em;
        }
        > span:last-child {
          text-align: left;
        }
      }
      > .sub_title-wrapper {
        margin: 20px;
      }
      .advantages_list {
        display: flex;
        justify-content: center;
        > li {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          border-radius: 24px;
          box-shadow:  0 0 5px 1px rgb(0, 0, 0, 0.1);
          padding: 30px 10px;
          margin-right: 50px;
          width: 290px;
          // height: 240px;
          > span:nth-child(2){
            font-size: 28px;
            padding: 20px 0;
          }
          > span {
            // border: 1px solid blue;
            font-size: 18px;
            line-height: 1.5;
          }
        }
        > li:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .sub_title {
    display: inline-block;
    background-color: #000;
    font-size: 24px;
    line-height: 32px;
    border-radius: 32px;
    padding: 8px 16px;
    color: #fff;
  }
  .features {
    background-color: #f5f5f5;
    padding-top: 36px;
    padding-bottom: 70px;
    > .content {
      .sub_title-wrapper {
        text-align: center;
        margin-bottom: 44px;
      }
      > .features_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1020px;
        margin: 0 auto;
        > li {
          width: 485px;
          // width: 25.26vw;
          min-height: 170px;
          border: 1px solid #000;
          margin-bottom: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > .image-wrapper {
            width: 140px;
            min-width: 140px;
            height: 80px;
            display: flex;
            justify-content: center;
            > img {
              height: 80px;
            }
          }
          > .text-wrapper {
            padding-left: 10px;
            > p {
              font-size: 16px;
              font-weight: 200;
            }
            > p:first-child{
              font-size: 28px;
              font-weight: normal;
              margin-bottom: 10px;
            }
          } 
        }
      }
    }
  }
  .awards {
    padding-top: 90px;
    padding-bottom: 75px;
    > .content {
      padding-top: 26px;
      > .honor-wrapper {
        width: 100%;
        min-height: 154px;
        background-image: url('../assets/honor.png');
        background-position: center;
        background-repeat: no-repeat;
      }
      > .img-wrapper {
        width: 100%;
        padding: 0 150px;
        > img {
          width: 100%;
          display: block;
          margin-top: -30px;
        }
      }
    }
  }
  
}

</style>
