<template>
  <div id="app">
    <Nav></Nav>
    <router-view/>
    <DeFooter></DeFooter>
  </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
import DeFooter from './components/DeFooter.vue'

export default {
  name: 'App',
  components: { Nav, DeFooter }

}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  min-width: 1200px;
  min-height: 100vh;
  font-size: 18px;
  color: #000;
}

#nav {
  padding: 30px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
a {
  color: #000;
  text-decoration: none; 
}
h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}
p {
  line-height: 1.5;
}
</style>
