var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"introduction"},[_c('UnderlineTitle',[_vm._v("公司介绍")]),_vm._m(1)],1),_c('div',{staticClass:"platforms"},[_c('UnderlineTitle',[_vm._v("合作出行平台")]),_vm._m(2)],1),_c('div',{staticClass:"advantages"},[_c('UnderlineTitle',[_vm._v("产品优势")]),_c('div',{staticClass:"content"},[_vm._m(3),_vm._m(4),_c('ul',{staticClass:"advantages_list"},_vm._l((_vm.advantagesList),function(item){return _c('li',{key:item.id},[_c('img',{attrs:{"src":item.imgSrc,"alt":""}}),_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.description_1))]),_c('span',[_vm._v(_vm._s(item.description_2))])])}),0)])],1),_c('div',{staticClass:"features"},[_c('div',{staticClass:"content"},[_vm._m(5),_c('ul',{staticClass:"features_list"},_vm._l((_vm.featuresList),function(item){return _c('li',{key:item.id},[_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":item.imgSrc,"alt":""}})]),_c('div',{staticClass:"text-wrapper"},[_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.description))])])])}),0)])]),_c('div',{staticClass:"awards"},[_c('UnderlineTitle',[_vm._v("公司荣誉")]),_vm._m(6)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-container banner"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide1"},[_c('img',{attrs:{"src":require("../assets/banner_1.jpg"),"alt":""}})]),_c('div',{staticClass:"swiper-slide swiper-slide2"},[_c('img',{attrs:{"src":require("../assets/banner_2.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../assets/intro.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('p',[_vm._v("迪尔出行是青岛路远信息技术有限公司打造的智慧出行生态平台，依托移动互联网技术构建的面向全国商旅出行人士的网络预约出租汽车经营服务方。")]),_c('p',[_vm._v("迪尔将以全新的姿态，全方位的布局，独特的运营思路，稳固的技术支撑，逐步实现品牌的行业基准，并引领行业更向前一步发展。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('p',[_vm._v("与高德、飞猪、各大企业用车达成战略合作，API深度对接，其他合作方紧密洽谈中…")]),_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":require("../assets/gaode_logo.png"),"alt":"高德地图logo"}}),_c('img',{attrs:{"src":require("../assets/feizhu_logo.png"),"alt":"飞猪logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogan"},[_c('span',[_vm._v("迪 尔 出 行")]),_c('img',{attrs:{"src":require("../assets/advantanges_logo.png"),"alt":"logo"}}),_c('span',[_vm._v("相 伴 每 一 程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title-wrapper"},[_c('span',{staticClass:"sub_title"},[_vm._v("主要服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title-wrapper"},[_c('span',{staticClass:"sub_title"},[_vm._v("产品特色")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"honor-wrapper"}),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("../assets/license.png"),"alt":"license"}})])])
}]

export { render, staticRenderFns }